import React, { useState } from 'react'
import { expandState, IArticleProps } from '.'
import { httpGet } from '../../services/http'
import './readMoreStyle.less'
import './style.less'

export function ArticleHistorie({ headerClass, textClass, linkClass }: IArticleProps) {
    const [expandText, setExpandText] = useState(expandState.closed)

    const clickRead = () => {
        httpGet(
            `/landing/article/historie/${
                expandText === expandState.closed ? 'start-read' : 'stop-read'
            }`
        )
        if (expandText === expandState.closed) {
            setExpandText(expandState.open)
        } else {
            setExpandText(expandState.closed)
        }
    }

    return (
        <div className="guide-container">
            <input type="checkbox" className="read-more-state" id="article-historie" />
            <div className="read-more-wrap">
                <h2 className={headerClass}>Historiske aspekter omkring loven</h2>
                <p className={`${textClass}`}>
                    Bestemmelsen kom i sin oprindelige udformning ind i lovgivningen med virkning
                    fra indkomståret 1976 (lov nr. 649 af 19. december 1975, jf. lovforslag L 97,
                    1975/76. )
                </p>
                <p className={`${textClass} read-more-target`}>
                    LL§33A blev ophævet af den daværende regering (S, RV, SF) d.13.09.2012, med{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className={linkClass}
                        href="https://www.folketingstidende.dk/samling/20111/lovforslag/L195/20111_L195_som_fremsat.pdf"
                    >
                        lovforslag nr. L 195 2011/2012
                    </a>
                    . Lovforslaget{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className={linkClass}
                        href="https://www.ft.dk/samling/20111/lovforslag/l195/index.htm"
                    >
                        blev vedtaget med 90 stemmer
                    </a>{' '}
                    for forslaget (V, S, RV, SF, EL, KF) 18 stemmer imod forslaget (DF, LA) 0
                    stemmer hverken for eller imod forslaget. LL§33A blev imidlertid genindført af
                    Folketinget ved 3. behandling den 19. december 2012, af{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className={linkClass}
                        href="https://www.retsinformation.dk/eli/ft/201212L00082"
                    >
                        lovforslag 2012/1 LSF 82
                    </a>{' '}
                    fremsat skatteminister Holger K Nielsen (SF)
                    <br />
                    <br />
                    Her et uddrag fra{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className={linkClass}
                        href="https://www.folketingstidende.dk/samling/20111/lovforslag/L195/20111_L195_som_fremsat.pdf"
                    >
                        lovforslag nr. L 195 2011/2012
                    </a>
                    , der bl.a. beskriver oprindelsen af §33A tilbage fra 1975:
                </p>
                <h3 className={`${headerClass} read-more-target`}>3.3.2. Lovforslaget</h3>
                <p className={`${textClass} read-more-target`}>
                    Det foreslås at ophæve ligningslovens § 33 A, da forudsætningerne for
                    bestemmelsen ikke længere er til stede. Det betyder, at der fremover kun kan
                    opnås lempelse i den danske skat af løn i udlandet i det omfang, der er betalt
                    skat af lønnen i udlandet. Forslaget indebærer, at de interne danske regler
                    tilrettes efter internationale standarder, således at det aldrig kan forekomme,
                    at lønnen ikke beskattes nogen steder.
                    <br />
                    <br />
                    Bestemmelsen kom i sin oprindelige udformning ind i lovgivningen med virkning
                    fra indkomståret 1976 (lov nr. 649 af 19. december 1975, jf. lovforslag L 97,
                    1975/76. ) Bestemmelsen tog sigte på den situation, at en medarbejder arbejder i
                    en periode i udlandet for en dansk arbejdsgiver. Tankegangen var, at den danske
                    arbejdsgiver i en sådan situation kun skulle afholde en lønudgift svarende til
                    beskatningsniveauet i opholdslandet – og det ville ofte være lavere end i
                    Danmark.
                    <br />
                    <br />
                    Bestemmelsen bygger således på en antagelse om, at lønindkomsten under
                    udlandsopholdet vil blive beskattet i udlandet efter de regler, der gælder dér.
                    Er skatten i udlandet lavere end i Danmark, skal Danmark ikke opkræve
                    differencen. Dette ville ellers være muligt efter bestemmelsen i ligningslovens
                    § 33. Efter denne bestemmelse nedsættes den danske skat højst med det beløb, der
                    faktisk er betalt i udlandet – denne metode kaldes »credit«.
                    <br />
                    <br />
                    Da ligningslovens § 33 A blev indført, var det stadig almindeligt at anvende
                    exemption som generel lempelsesmetode i dobbeltbeskatningsoverenskomster. Det er
                    det ikke længere. Danmark accepterer ikke længere exemption som generel
                    lempelsesmetode og har i mange år ikke gjort det. Begrundelsen er, at
                    exemptionsmetoden kan give mulighed for beskatning på et meget lavt niveau eller
                    endda skattefritagelse i begge lande – også kaldet »dobbelt ikke-beskatning«.
                    <br />
                    <br />
                    I stedet anvender Danmark i dag credit som generel lempelsesmetode. Dette sikrer
                    en samlet beskatning på dansk niveau, og det hindrer spekulation i forskelle i
                    beskatningsniveauer landene imellem. Danmark anvender dog exemptionsmetoden i
                    forbindelse med lønindkomst, når det drejer sig om indkomst optjent i Sverige,
                    Norge og Tyskland. Dette skyldes, at man i disse lande opkræver meget høje
                    sociale bidrag hos arbejdsgiverne. Disse bidrag kan man ikke indregne i en
                    creditlempelse, da de som nævnt ikke påhviler lønmodtageren, men arbejdsgiveren.
                    Derfor anvendes exemptionsmetoden, men kun i de tilfælde, hvor disse store
                    arbejdsgiverbidrag er betalt. Hvis disse bidrag ikke er betalt
                    <br />
                    <br />
                    – fordi den pågældende er socialt sikret i Danmark – anvendes creditmetoden.
                    <br />
                    <br />
                    I dag anvendes ligningslovens § 33 A ikke blot af personer, som arbejder i
                    udlandet for en dansk arbejdsgiver i en periode. Der er langt større
                    bevægelighed i dag, end der var for 35 år siden, og det er på ingen måde
                    usædvanligt, at en person i en kortere eller længere periode arbejder i udlandet
                    for en udenlandsk arbejdsgiver, samtidig med at personen har bevaret sin fulde
                    skattepligt til Danmark. Anvendes bestemmelsen, kan personen undgå at betale
                    skat til Danmark, samtidig med, at den pågældende og en eventuel familie opnår
                    de fordele af velfærdsmæssig karakter, som tilhørsforholdet til Danmark
                    medfører. Fordelen er upåvirket af, om personen indretter sig, så der opnås en
                    meget lav eller måske slet ingen beskatning i udlandet efter de regler, der
                    gælder dér.
                    <br />
                    <br />
                    Når ligningslovens § 33 A afskaffes, vil personer, der opholder sig i udlandet,
                    i stedet kunne få creditlempelse for skat, der er betalt i udlandet, ved at
                    anvende ligningslovens § 33 eller de dobbeltbeskatningsoverenskomster, som
                    Danmark har indgået. Dobbeltbeskatning kan således stadig undgås.
                </p>
                <label htmlFor="article-historie" className="read-more-trigger" onClick={clickRead}>
                    {expandText}
                </label>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import { expandState, IArticleProps } from '.'
import { httpGet } from '../../services/http'
import './readMoreStyle.less'

export function ArticleLovTekst({ headerClass, textClass, linkClass }: IArticleProps) {
    const [expandText, setExpandText] = useState(expandState.closed)

    const clickRead = () => {
        httpGet(
            `/landing/article/lov-tekst/${
                expandText === expandState.closed ? 'start-read' : 'stop-read'
            }`
        )
        if (expandText === expandState.closed) {
            setExpandText(expandState.open)
        } else {
            setExpandText(expandState.closed)
        }
    }

    return (
        <div className="guide-container">
            <input type="checkbox" className="read-more-state" id="article-lovtekst" />
            <div className="read-more-wrap">
                <h2 className={headerClass}>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        className={linkClass}
                        href="https://www.retsinformation.dk/eli/lta/2019/806#P33A"
                    >
                        Lov Tekst
                    </a>
                </h2>
                {/* <p className={textClass}>
                    <a target="_blank" rel="noreferrer" className={linkClass} href='https://www.retsinformation.dk/eli/lta/2019/806#P33A'>www.retsinformation.dk</a>
                </p> */}
                <p className={textClass}>
                    § 33 A. Har en person, der er skattepligtig efter kildeskattelovens § 1, under
                    ophold uden for riget i mindst 6 måneder uden andre afbrydelser af opholdet end
                    nødvendigt arbejde her i riget i direkte forbindelse med udlandsopholdet, ferie
                    el.lign. af en sammenlagt varighed på højst 42 dage erhvervet lønindkomst ved
                    personligt arbejde i tjenesteforhold, nedsættes den samlede indkomstskat med det
                    beløb, der forholdsmæssigt falder på den udenlandske indkomst.
                </p>
                <p className={`${textClass} read-more-target`}>
                    Nedsættelsen omfatter ikke den del af lønindkomsten, der kan henføres til
                    arbejde her i landet. Ved opgørelsen af den nævnte 6-månedersperiode anses
                    ophold om bord på et dansk skib, der er registreret i Dansk Internationalt
                    Skibsregister, jf. lov om beskatning af søfolk, for at være ophold uden for
                    riget. Ophører skattepligten efter kildeskattelovens § 1 inden udløbet af
                    6-månedersperioden, finder reglerne i denne bestemmelse tilsvarende anvendelse
                    på lønindkomst erhvervet i den periode, hvor skattepligten bestod, såfremt
                    betingelserne i 1. pkt. i øvrigt er opfyldt. Stk. 2. Stk. 1 finder ikke
                    anvendelse for lønindkomst ved tjeneste uden for riget for den danske stat eller
                    anden dansk offentlig myndighed, såfremt erhververen af den nævnte lønindkomst
                    modtager ydelser efter § 7, nr. 15, eller § 9 A. Tilsvarende finder stk. 1 ikke
                    anvendelse for lønindkomst erhvervet fra den danske stat eller anden offentlig
                    myndighed, hvis lønnen er fastsat i henhold til en kollektiv aftale og det ikke
                    udtrykkeligt fremgår af denne aftale, at lønnen er fastsat under hensyn til
                    lempelse efter stk. 1. Stk. 3. Har en dobbeltbeskatningsoverenskomst for en
                    person, der ikke er udsendt af den danske stat eller en anden offentlig
                    myndighed, tillagt Danmark beskatningsretten til indkomsten, nedsættes den
                    samlede indkomstskat med halvdelen af det beløb, der forholdsmæssigt falder på
                    den udenlandske indkomst. Tilsvarende gælder for personer udsendt til udførelse
                    af systemeksport for den danske stat eller anden dansk offentlig myndighed. Stk.
                    4. Skatteministeren kan fastsætte regler om henstand med opkrævning af skatten.
                    Stk. 5. Stk. 1-4 finder tilsvarende anvendelse på dødsboer omfattet af
                    dødsboskattelovens § 1, stk. 2.
                </p>
                <label htmlFor="article-lovtekst" className="read-more-trigger" onClick={clickRead}>
                    {expandText}
                </label>
            </div>
        </div>
    )
}

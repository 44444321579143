import { Collapse } from 'antd'
import { CheckCircleOutlined, RightOutlined } from '@ant-design/icons'
import React from 'react'
import './style.less'
import { ArticleAfbrydelse } from './Afbrydelse'

const { Panel } = Collapse

export interface IArticleProps {
    headerClass: string
    textClass: string
    linkClass: string
}

export enum expandState {
    closed = 'read more...',
    open = '...read less',
}

export function ArticlesCollapse() {
    return (
        <>
            <div className="content-row" style={{ marginTop: '2rem' }}>
                <h1 className="inverse-h">Information about LL § 33 A</h1>
            </div>
            <Collapse
                defaultActiveKey={[]}
                ghost
                // expandIconPosition='right'
                expandIcon={() => {
                    return <div className="content-row"></div>
                }}
            >
                <Panel
                    header={
                        <div className="content-row">
                            <h3
                                className="inverse-h expandable-header"
                                style={{ maxWidth: '600px' }}
                            >
                                <RightOutlined className="inverse-h expandable-header" />
                                {'  '}
                                Udlandsopholdets afbrydelse eller ophør af upåregnelige årsager
                            </h3>
                        </div>
                    }
                    key="1"
                >
                    <div className="content-row">
                        <div className="text-row">
                            <ArticleAfbrydelse
                                headerClass="inverse-h"
                                linkClass="inverse-h"
                                textClass="inverse-h"
                            />
                        </div>
                    </div>
                </Panel>
                <Panel
                    header={
                        <div className="content-row">
                            <h3
                                className="inverse-h expandable-header"
                                style={{ maxWidth: '600px' }}
                            >
                                <CheckCircleOutlined className="inverse-h expandable-header" />
                                {'  '}Collecting & storing documentation
                            </h3>
                        </div>
                    }
                    key="2"
                >
                    <div className="content-row">
                        <div className="text-row">
                            <p className="inverse-h" style={{ maxWidth: '600px' }}>
                                Collecting, storing and presenting the required documentation to the
                                tax authorities, has now become easy by the “LL§33A” app as well.
                                Once you have claimed §33A on your tax filling for a given tax year,
                                you are obliged to make available for the tax authorities, the
                                documentation of your adherence to the 42-day-rule, upon ever more
                                frequent tax-authority scrutiny. This is all handled with ease by
                                the app, only depriving you a few seconds for each piece of
                                documentation, that you have obtained to proof your whereabouts
                                outside Denmark on a given day. Wether that, be a receipt you
                                capture through the app’s camera access on you phone, or from your
                                photo roll, or a document from files, or should you choose to let
                                the app GEO* track you; all will be stored in relation to the day
                                selected in the app, with cloud backup* of your choice ie.iCoud,
                                DropBox, OneDrive etc.
                            </p>
                        </div>
                    </div>
                </Panel>
                <Panel
                    header={
                        <div className="content-row">
                            <h3
                                className="inverse-h expandable-header"
                                style={{ maxWidth: '600px' }}
                            >
                                <CheckCircleOutlined className="inverse-h expandable-header" />
                                {'  '}Compile full compliance report for SKAT
                            </h3>
                        </div>
                    }
                    key="3"
                >
                    <div className="content-row">
                        <div className="text-row">
                            <p className="inverse-h" style={{ maxWidth: '600px' }}>
                                Once the documentation is requested from you by the tax authority,
                                you simply select the exemption period requested, in the app’s Doc
                                Generator, which then generates the required document in a pdf
                                format, enabling you to easily forward it to the requesting
                                authority.
                            </p>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}

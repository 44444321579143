import React from 'react'
import { IArticleProps } from '.'
import './readMoreStyle.less'
import './style.less'

export function ArticleCF4224({ headerClass, textClass, linkClass }: IArticleProps) {
    return (
        <div className="guide-container">
            <h2 className={headerClass}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={linkClass}
                    href="https://info.skat.dk/data.aspx?oid=1977320"
                >
                    C.F.4.2.2.4 - 42-dages-reglen
                </a>
            </h2>
            <p className={`${textClass}`}>
                Dette afsnit beskriver reglen om, at ophold her i riget højst må udgøre 42 dage
                inden for enhver 6-måneders periode.
            </p>
            <p className={`${textClass}`}>
                Afsnittet indeholder:
                <br />
                - Nødvendigt arbejde her i riget i direkte forbindelse med udlandsopholdet
                <br />
                - Brudte døgn
                <br />
                - Ophold i riget inden for de første 6 måneder
                <br />
                - Ophold her i riget ved ophold i udlandet i mere end 6 måneder
                <br />
                - Midlertidig lempelse af 42-dages-reglen
                <br />- Oversigt over domme, kendelser, afgørelser, SKM-meddelelser mv.
            </p>
        </div>
    )
}

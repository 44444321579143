import React from 'react'
import { IArticleProps } from '.'
import './readMoreStyle.less'
import './style.less'

export function ArticleCF4223({ headerClass, textClass, linkClass }: IArticleProps) {
    return (
        <div className="guide-container">
            <h2 className={headerClass}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={linkClass}
                    href="https://info.skat.dk/data.aspx?oid=1977319"
                >
                    C.F.4.2.2.3 - Mindst 6 måneder
                </a>
            </h2>
            <p className={`${textClass}`}>
                Dette afsnit beskriver, hvad der anses for og ikke anses for ophold uden for riget i
                mindst 6 måneder set i relation til{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={linkClass}
                    href="https://www.retsinformation.dk/eli/lta/2019/806#P33A"
                >
                    LL § 33 A
                </a>
                .
            </p>
            <p className={`${textClass}`}>
                Afsnittet indeholder:
                <br />
                - 6-måneders-perioden
                <br />
                - Ferie eller lignende afholdt i udlandet
                <br />
                - Ferie eller lignende afholdt her i riget
                <br />
                - Udlandsopholdets afbrydelse eller ophør af upåregnelige årsager
                <br />
                - Midlertidig lempelse af 6-måneders- reglen
                <br />- Oversigt over domme, kendelser, afgørelser, SKM-meddelelser mv.
            </p>
        </div>
    )
}
